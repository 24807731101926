import React from "react";


import "./App.css";
import { Route, Routes } from "react-router-dom";

 import ScrollToTop from "./main-components/scrool-to-top";
import Navbar from "./main-components/navbar/navbar";

 import Monisha from "./pages/Monisha";
 import About from "./pages/about";
 import Service from "./pages/Service/Service";
 import Project from "./pages/Project/Project";
 import Contact from "./pages/Contact/Contact";

 import Wardobes from "./pages/service-dropdown-pages/Wardobes/Wardobes";
 import Poojaunit from "./pages/service-dropdown-pages/Poojaunit/Poojaunit";
 import Tvunit from "./pages/service-dropdown-pages/Tvunit/Tvunit";
 import Dressing from "./pages/service-dropdown-pages/Dressing/Dressing";
 import Bathroom from "./pages/service-dropdown-pages/Bathroom/Bathroom";
 import False from "./pages/service-dropdown-pages/False/False";

 import Banglore from "./pages/Banglore/Banglore";
import Hydrabad from "./pages/Hydrabad/Hydrabad";
import Chennai from "./pages/Chennai/Chennai";
import Vellore from "./pages/Vellore/Vellore";
import Tirupathur from "./pages/Tirupathur/Tirupathur";
import Tiruvannamalai from "./pages/Thiruvannamalai/Tiruvannamalai";
import Dharmapuri from "./pages/Dharmapuri/Dharmapuri";
//  import Moduler from"./pages/service-dropdown-pages/Service/Service";





function App(){


  return (
    <>
      <Navbar />
      {/* <New /> */}
      <></>
      <Routes>
        <Route path="/" element={<Monisha />} />
        <Route
          path="/about-us"
          element={
            <>
              {" "}
              <ScrollToTop />
              <About />{" "}
            </>
          }
        />
         <Route
          path="/Services"
          element={
            <>
              {" "}
              <ScrollToTop />
              <Service/>{" "}
            </>
          }
        /> 
         <Route
          path="/Project"
          element={
            <>
              <ScrollToTop />
              <Project />
            </>
          }
        />
        <Route
          path="/Contact-us"
          element={
            <>
              <ScrollToTop />
              <Contact />
            </>
          }
        />
          <Route
          path="/Service"
          element={
            <>
              {" "}
              <ScrollToTop />
              <Service />
            </>
          }
        />  
         <Route
          path="/Wardobes"
          element={
            <>
              {" "}
              <ScrollToTop />
              <Wardobes />
            </>
          }
        /> 
        <Route
          path="/poojaunit"
          element={
            <>
              {" "}
              <ScrollToTop />
              <Poojaunit />
            </>
          }
        />
        <Route
          path="/Tvunit"
          element={
            <>
              <ScrollToTop /> <Tvunit/>
            </>
          }
        />
        <Route
          path="/Dressing"
          element={
            <>
            
              <ScrollToTop />
              <Dressing />{" "}
            </>
          }
        />

        <Route
          path="/Bathroom"
          element={
            <>
            
              <ScrollToTop />
              <Bathroom/>{" "}
            </>
          }
        />
         <Route
          path="/False"
          element={
            <>
            
              <ScrollToTop />
              <False/>{" "}
            </>
          }
        />
         <Route
          path="/Banglore"
          element={
            <>
            
              <ScrollToTop />
              <Banglore/>{" "}
            </>
          }
        />
        <Route
          path="/Hydrabad"
          element={
            <>
            
              <ScrollToTop />
              <Hydrabad/>{" "}
            </>
          }
        />
       <Route
          path="/Chennai"
          element={
            <>
            
              <ScrollToTop />
              <Chennai/>{" "}
            </>
          }
        />
     
      <Route
          path="/Vellore"
          element={
            <>
            
              <ScrollToTop />
              <Vellore/>{" "}
            </>
          }
        />
        <Route
          path="/Tirupathur"
          element={
            <>
            
              <ScrollToTop />
              <Tirupathur/>{" "}
            </>
          }
        />
        <Route
          path="/Tiruvannamalai"
          element={
            <>
            
              <ScrollToTop />
              <Tiruvannamalai/>{" "}
            </>
          }
        />
        <Route
          path="/Dharmapuri"
          element={
            <>
            
              <ScrollToTop />
              <Dharmapuri/>{" "}
            </>
          }
        />
        
        </Routes>
      

      {/* <Footer /> */}
      {/* <NavBar/> */}
    </>
  );
}

export default App;
