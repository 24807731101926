import React from 'react';

import '../assests/css/about.css';
import Footer from './Footer/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';


// R-Icons 
import { CiHome } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { GoGoal } from "react-icons/go";
import { FaRegEye } from "react-icons/fa6";

// Images 
import aboutBanner from '../../src/assests/images/monisha/4.jpeg';
import aboutSec1 from '../assests/images/monisha/abt1.jpg';
import aboutSec2 from '../assests/images/monisha/home/2.jpg';
import aboutProject from '../assests/images/monisha/service/bathroom.jpg'
import aboutProject1 from '../assests/images/monisha/kid1.jpg'
import aboutProject2 from '../assests/images/monisha/abt4.jpg'
import aboutProject3 from '../assests/images/monisha/service/modulerkitchen.jpg'
import aboutProject4 from '../assests/images/monisha/abt6.jpg'


function About() {
  return (
    <>
     <Helmet>
        <title>interior decorators in Hosur</title>
        <meta name="description" content="Welcome to Monisha Interiors, where creativity meets functionality to transform spaces into your dream haven. As leading interior decorators in Hosur, our passion lies in crafting personalized, aesthetic environments that resonate with your style and preferences."

/>
       
        {/* Other meta tags, link tags, etc. */}
      </Helmet>
      <section className="about-banner">
        <div className="about-banner-images">
          <img src={aboutBanner} alt="aboutBanner" />

          <div className="about-content">
            <h1>About Us</h1>
          </div>
          <div className="about-banner-nav">
            <div className="aboutNav">
               <CiHome className='some' /> <Link to="/" ><span>Home</span> </Link> / About 
            </div>

          </div>
        </div>
      </section>

      {/* About Banner End  */}

      <section className="about-section">
        <div className="about-page-container container">
          <div className="about-section-content">
            <span>About Monisha Interior</span>
            <h2>Unleashing the Power of Imagination, Redefining Spaces with Us</h2>
            <h4>We have been committed to providing the best services in the business since 2009.</h4>

            <p><b>Diverse aesthetics:</b> Whether you're looking to revamp your home, office, or commercial space, our interior design services in Hosur are tailored to meet your specific needs. From concept to execution, we prioritize attention to detail, ensuring a seamless and enjoyable design journey for our clients..</p>

            <p><b>Attention to detail:</b> We pay close attention to every little detail in your interior design, making sure that nothing is overlooked and that every little detail enhances the overall look.</p>

            <p><b>Color expertise:</b> Thanks to our in-depth knowledge of the color wheel, we can create aesthetically pleasing and calming color schemes that improve the atmosphere of your places.</p>

          </div>
          <div className="about-section-images">
            <img src={aboutSec1} className='about-img1' alt="about-sec" />
            <img src={aboutSec2} className='about-img2' alt="about-sec" />

            <div className="about-sec-exp">
              <h2>15Years</h2>
              <span>of Experiences</span>
            </div>
          </div>
        </div>
      </section>

      {/* About Section End  */}

      {/* About Mini Content Start */}

      <section className="about-mini-content">
        <div className="about-mini-container container">
          <div className="about-content-box">
            <h2>Interior designers In Hosur</h2>
            <p>At Monisha Interiors are interior designers in hosur, we pride ourselves on our commitment to excellence, bringing years of expertise to every project. Our team of skilled professionals is dedicated to understanding your vision and translating it into a harmonious blend of colors, textures, and furnishings that reflect your unique personality.</p>

            <Link to="/Contact-us">
              <span className='about-connect'>
                Connect to Experts
              </span>
            </Link>
          </div>
        </div>
      </section>

      {/* About Mini Content End */}

      {/* About Project Start */}

      <section className="about-project-section">
        <div className="about-project-container container">
          <div className="about-project-title">
            <span>Our Projects</span>
            <h2>Showcasing Our <span>Latest Masterpieces</span> </h2>
          </div>
          <div className="about-project-content">
            <div className="about-project-background">
              <div className="about-project-contact">
                <p>Do You Have Any Questions? Call.</p>
                <IoCallOutline className='about-call' />
                <h5>Contact</h5>
                <a href="tel:9788537772">9788537772</a>
                <a href="tel:9443348032">9443348032</a>
              </div>
            </div>
            <div className="about-project-images">
              <div className="about-images-contain">
                <img src={aboutProject1} alt="ourProjects" />
                <img src={aboutProject2} alt="ourProjects" />
                <img src={aboutProject3} alt="ourProjects" />
                <img src={aboutProject4} alt="ourProjects" />
              </div>
              <div className="about-mission-vision">
                <h2>Interior Design In Hosur</h2>
                <h3>Creating the Art of Stylish Living</h3>
                <p>Step into the world of Monisha Interiors, where we take pride in creating the art of stylish living. As seasoned interior decorators in Hosur, our mission is to elevate your living spaces with a perfect blend of sophistication, functionality, and a touch of artistic flair.</p>
                <div className="about-mission-container">
                  <div className="about-mission-boxs">
                    <GoGoal className='about-mis-icon' />
                    <h3>Mission</h3>
                    <p>Our mission extends beyond the conventional boundaries of interior design; we aim to enhance the quality of life for our clients. We believe that a well-designed space has the power to evoke emotions, boost productivity, and foster a sense of well-being.</p>
                  </div>
                  <div className="about-mission-boxs">
                    <FaRegEye className='about-mis-icon' />
                    <h3>Vision</h3>
                    <p>In our vision for the future, Monisha Interiors is not just a design firm; it's a destination for those seeking a harmonious blend of style, comfort, and functionality. We aim to be recognized not only for our aesthetic prowess but also for the transformative impact our designs have on the lives of our clients.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
<Footer/>
      {/* About Project End */}
    </>
  )
}

export default About