import React from "react";
import './Whychooseme.css';


function Whychooseme() {
    return (
           <div className="about-provides">

            <div className="about-provide-container container">
                <div className="about-provide-title">

                    <div className="about-provide-divider"> <h1>Why Choose Me?</h1> </div>
                </div>
                <div className="about-provide-boxs">

                    <div className="we-provide">
                        <div className="weprovideimage-round">
                            <img className="we-provide-image" src="images/monisha/home/warrenty.png" />
                        </div>
                        <p><h6>15 Year Warranty</h6>Good performance and reliable quality products with no room for flaws and defects.</p>
                    </div>


                    <div className="we-provide">
                        <div className="weprovideimage-round">
                            <img className="we-provide-image" src="images/monisha/home/headphone.png" />
                        </div>
                        <p><h6>Consultation</h6>A friendly consultation is all it takes to brighten someone's day and lighten their load.".</p>
                    </div>


                    <div className="we-provide">
                        <div className="weprovideimage-round">
                            <img className="we-provide-image" src="images/monisha/home/key.png" />
                        </div>
                        <p><h6>Under 20-Days Delivery</h6>2 BHK kitchen+Dinning+Bedroom     <br/>Duplex + 10 Days</p>
                        {/* <p><h6>Under 30-Days Delivery</h6>Duplex kitchen+Dinning+Bedroom</p> */}
                    </div>




                    {/* we provide second line */}


                    <div className="we-provide">
                        <div className="weprovideimage-round">
                            <img className="we-provide-image" src="images/monisha/home/bulb.png" />
                        </div>
                        <p><h6>Unique Design</h6>Irreplaceable unique designs to suit everyone’s palette with the expertise of our professionals</p>
                    </div>


                    <div className="we-provide">
                        <div className="weprovideimage-round">
                            <img className="we-provide-image" src="images/monisha/home/3d design.png" />
                        </div>
                        <p><h6>Live 3d Design</h6>Quick and accurate visualization for your home interiors.</p>
                    </div>


                    <div className="we-provide">
                        <div className="weprovideimage-round">
                            <img className="we-provide-image" src="images/monisha/home/certificate.png" />
                        </div>
                        <p><h6>Premium Quality Products</h6>Quality products in terms of cost and durability.</p>
                    </div>
                </div>




            </div>


        </div>




    )
}
export default Whychooseme;
