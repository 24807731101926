import React, { useState } from "react";
import "./Aboutfaq2.css";

const Aboutfaq2 = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const handleQuestionClick = (index) => {
    setActiveQuestion((prevActiveQuestion) => (prevActiveQuestion === index ? null : index));
  };

  const faqData = [
    // ... (your FAQ data remains unchanged)
    {
        question: "What services does Monisha Interior in Hosur offer?",
        answer: "Monisha Interior provides a wide range of services, including uPVC solutions for modern kitchens, wardrobes, pooja cabinets, false ceilings, TV showcases, bathrooms, and dressing tables. The best interior in Hosur.",
      },
      {
        question:  "Why choose uPVC for interior solutions?",
        answer: "UPVC is chosen for its versatility, durability, and modern aesthetic appeal. It ensures longevity, low maintenance, and resistance to wear and tear, making it an ideal material for crafting elegant and functional interiors. Interior decorators in Hosur",
      },
      {
        question:  "How does Monisha Interior approach the design process?",
        answer: "Our design process begins with strategic planning, where we analyze spaces and tailor a roadmap to align with the client's vision. We then move on to developing creative concepts, refining them during the design development phase, and finally executing the construction work with precision and attention to detail. Interior designers in Hosur.",
      },
      {
        question:  "What sets Monisha Interior apart from other interior design firms?",
        answer: "Monisha Interior stands out due to our commitment to excellence, years of experience in the industry, and a holistic approach to interior design. Our focus on customer satisfaction, creativity, and skilled craftsmanship ensures that every project is a unique masterpiece. The best interior designers in Hosur.",
      },
      {
        question:  "How many years of experience does Monisha Interior have?",
        answer: "Monisha Interior boasts a number of years of experience in the interior design industry, providing a wealth of knowledge and expertise to deliver exceptional results on every project. Interior design in Hosur.",
      },
      {
        question:  "Can you provide references or testimonials from satisfied clients?",
        answer: "Yes, we have a growing list of satisfied clients who have experienced the excellence of Monisha Interior. Testimonials and references can be provided upon request to showcase our commitment to client satisfaction. Interior decorators in Hosur.",
      },
     
  ];

  return (
    <div className="container according-wraper">
      <h1>FAQs About Monisha Interior</h1>

      <div className="">
        {faqData.map((faq, index) => (
          <div key={index} className={`accoeden-con ${index === activeQuestion ? "active" : ""}`}>
            <div className="question" onClick={() => handleQuestionClick(index)}>
              {faq.question}
            </div>
            <div className="answercont" style={{ maxHeight: index === activeQuestion ? "100%" : "0" }}>
              <div className="answer">{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Aboutfaq2;
