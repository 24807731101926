import React from "react";
import './Cardhover2.css';
function Cardhover2(){
    return(

      
      
        <div className="servicecard-hover-container container">
         
  <div className="servicecard-hover-box">
    <div className="servicecard-hover-img">
        <img src="/images/monisha/service/dressing table.jpg" height="350px" width="350px"/>
    </div>
    <div className="overlay"></div>
    <div className="overlay2"></div>
    <div className="text">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
        <h2>Dressing Table</h2> 
      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
    </div>
  </div>
  <div className="servicecard-hover-box">
    <div className="servicecard-hover-img2">
    <img src="/images/monisha/service/bathroom2.webp" height="350px" width="350px"/>
    </div>
    <div className="overlay"></div>
    <div className="overlay2"></div>
    <div className="text">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
        <h2>Bathroom Doors</h2> 
      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
    </div>
  </div>
  <div className="servicecard-hover-box">
    <div className="servicecard-hover-img3">
    <img src="/images/monisha/service/false.webp" height="350px" width="350px"/>
    </div>
    <div className="overlay"></div>
    <div className="overlay2"></div>
    <div className="text">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
       <h2>False Ceilling</h2> 
      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
    </div>
  </div>
  <div className="servicecard-hover-box">
    <div className="servicecard-hover-img4">
    <img src="/images/monisha/service/moduler/cpu.jpg" height="350px" width="350px"/>
    </div>
    <div className="overlay"></div>
    <div className="overlay2"></div>
    <div className="text">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
        <h2>Table Unit</h2> 
      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
    </div>
  </div>
  
<br/>

  
</div>


    )
}
export default Cardhover2;