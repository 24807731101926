import React from "react";
import'./Project.css';

import { Link } from 'react-router-dom';
import { CiHome } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { GoGoal } from "react-icons/go";
import { FaRegEye } from "react-icons/fa6";
import Footer from "../Footer/Footer";


// import aboutBanner from '../../src/assests/images/monisha/4.jpeg';
// import Banglore from './../Banglore/Banglore';
// import Dharmapuri from './../Dharmapuri/Dharmapuri';
function Project(){
    return(
          
<div>
           {/* ..project banner start.... */}
        <section className="about-banner">
        <div className="about-banner-images">
          <img src="/images/monisha/4.jpeg"/>

          <div className="about-content">
            <h1>Projects</h1>
          </div>
          <div className="about-banner-nav">
            <div className="aboutNav">
             <CiHome className='some' /> <Link to="/about-us" ><span>About</span> </Link> / Projects 
            </div>

          </div>
        </div>
      </section>
{/* .....................banner end.......... */}
        <div className="container">
        <div className="section-title">
            <h1></h1>
        </div>

        <div className="row">

            <div className="column">
                
                <div className="effect">
                    <div className="effect-img">
                       <img src="/images/monisha/service/wardobes/2.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Coimbatore</h2>
                           
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/tv unit/2.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Hosur</h2>
                            
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/poojaunit/2.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Krishnagiri</h2>
                           
                        
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/moduler/11.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Banglore</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/moduler/15.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Dharmapuri</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/tv unit/10.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Hosur</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/tv unit/8.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Dharmapuri</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/tv unit/12.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Rayapettai</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/poojaunit/8.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Bangalore</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/moduler/14.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Palacode</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/wardobes/5.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Vellore</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                   
                    <img src="/images/monisha/service/board/4.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Denkanikottai</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/board/2.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                        <h2>Denkanikottai</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/wardobes/3.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Bangalore</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/tv unit/13.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Erode</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/tv unit/14.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Marenahalli</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/tv unit/3.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Marenahalli</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/tv unit/15.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Chennai</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/wardobes/11.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Bangalore</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/wardobes/4.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Hosur</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/poojaunit/5.jpg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Bangalore</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/false/4.jpeg"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Hosur</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/cputable/2.webp"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Hosur</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="column">
                <div className="effect">
                    <div className="effect-img">
                    <img src="/images/monisha/service/cputable/1.webp"/>
                    </div>
                    <div className="effect-text">
                        <div className="inner">
                            <h2>Hosur</h2>
                            {/* <div className="effect-btn">
                                <a href="#" className="btn"><i className="fa fa-eye"></i> Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
         
        </div>
       
    </div>
<Footer/>
</div>


    )

}
export default Project;
