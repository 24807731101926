import React from "react";
import './Monisha.css';
import Whychooseme from "./Whychooseme";
// import Navmenu from './Navmenu';
import Aboutservices from "./Aboutservices";


import Cardhover from "./Cardhover";

import Cardhover2 from "./Cardhover2";
import Callingus from "./Callingus";
import Aboutfaq2 from "./Aboutfaq2";
import Footer from "./Footer/Footer";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// import Testimonial from "./Testimonial";





function Monisha() {
  return (
    <div>
      <Helmet>
        <title>Transforming Spaces with Elegance by Monisha Interior Decorators in Hosur. </title>
        <meta name="description" content="Elevate your living spaces with our creative and innovative solutions. Unleash the potential of your home with our
         expert touch. Transforming Spaces with Elegance: Monisha Interior Decorators in Hosur." />

        {/* Other meta tags, link tags, etc. */}
      </Helmet>

      <div className="video-container">
        <video
          src="/images/monisha/SALA.mp4"

          autoPlay
          loop
          muted
          className="video-element"




        />

        <div className="video-info">
          <div class="vl-banner-video"></div> 

          <div className="video-banner-paragraph">
            <h1 className="video-head">Interior Designers in Hosur</h1>
            <p className="video-para"> Welcome to a realm of design where your dreams meet our expertise, and together,
              we create spaces that resonate with your soul</p>
            <Link to="/Contact-us"> <button className="video-button">Contact Us</button> </Link>
          </div>
        </div>
      </div>
      {/* ................carousel page... */}


      {/* //  .........about page...... */}
      <div className="about-page">


        {/*.......... about us and iframe .....................*/}
        <div className="aboutus-page ">
          <div className="aboutus-container container">
            <div className="aboutus-boxes">
              <div className="aboutus-image">
                <img src="images/monisha/home/3.jpeg" />
              </div>
              <div className="abouts-para">
                <h2 className="who-we-are heading">WHO WE ARE</h2>
                <h1> Interior designers in Hosur—Monisha Interior in Hosur</h1>
                <p>At Monisha Interior, located in the heart of Hosur, we are passionate creators of exceptional living spaces, specializing in transforming houses into havens. With a number of years of experience, our commitment to excellence is reflected in every project we undertake. We embrace the versatility and durability of uPVC, ensuring not only a modern aesthetic but also longevity, low maintenance, and resistance to wear. Each piece is crafted with precision by our skilled craftsmen, promising a seamless integration of uPVC into your interiors. Join our growing list of satisfied clients whose testimonials attest to our commitment to delivering beyond expectations.
                  We have a team of the best <span className="aboutus-span">interior designers in Hosur</span>, to do you dream designs as dream home.</p>


                <Link to="about-us"><button className="aboutus-button">About Us</button></Link>
                {/* ......................who we are start............................. */}
                <div className="whoweare-service">

                  <div className="whoweare-service-icons">
                    <img src="images/monisha/home/price2.png" />
                    <p>3 Yrs Free Service</p>
                  </div>

                  <div className="whoweare-service-icons">
                    <img src="images/monisha/home/design2.png" />
                    <p> 15 Years Warranty</p>
                  </div>

                  <div className="whoweare-service-icons">
                    <img src="images/monisha/home/warranty2.png" />
                    <p>Transparent pricing</p>
                  </div>


                </div>

              </div>

            </div>

          </div>

        </div>






      </div>
      {/* .......... who we are page end........ */}
      {/* .....why choose us page started.......... */}





      <Cardhover />
      <Cardhover2 />
      
      <Whychooseme />
      <Callingus />
      <Aboutservices />
      <Aboutfaq2 />
      {/* <Testimonial/> */}
      <Footer />
      




    </div>














  )
}

export default Monisha;