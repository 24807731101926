import React, { useState } from "react";
import './False.css';
import { Link } from 'react-router-dom';
import { CiHome } from "react-icons/ci";
import Footer from "../../Footer/Footer";
import { Helmet } from 'react-helmet';
// import Cardhover from "../Cardhover";
//import Aboutfaq2 from "../Aboutfaq2";

const False = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (name === '' || email === '' || number === '' || message === '') {
            alert("Please fill in all fields");
        } else {
            const formattedMessage = encodeURIComponent(
                "Contact Page info" + "\n" +
                " Name : " + name + "\n" +
                " Email : " + email + "\n" +
                " Mobile Number : " + "+" + number + "\n" +
                " Message : " + message
            );

            const url = "https://wa.me/+919788537772?text=" + formattedMessage;
            window.open(url, '_blank');
        }
    };

    return (
        <div>
            <Helmet>
        <title>Crafting Elegance Above uPVC False Ceiling Interior Design in Hosur</title>
        <meta name="description" content="Enhance your space with sophistication through Monisha Interior's uPVC false ceiling interior design services in Hosur. Immerse yourself in expert design consultations, meticulous space planning, captivating lighting design, and curated color palette selections. Elevate your ceiling to a work of art, where quality meets aesthetics seamlessly. Discover why Monisha Interior is the unparalleled choice for creating stunning false ceiling designs tailored to your unique."

/>
       
        {/* Other meta tags, link tags, etc. */}
      </Helmet>
            {/* service banner start.... */}
            <section className="about-banner">
                <div className="about-banner-images">
                    <img src="/images/monisha/4.jpeg" />

                    <div className="about-content">
                        <h1>False Ceilling Interior</h1>
                    </div>
                    <div className="about-banner-nav">
                        <div className="aboutNav">
                        <CiHome className='some' /> <Link to="/" ><span>Home</span> </Link> / False
                        </div>

                    </div>
                </div>
            </section>

            {/* .....................banner end.......... */}

            {/* service left page start.. */}
            <div className="more-servicepage-section">
                <div className="more-servicepage-container container">
                    <div className="more-servicepage-boxes">


                        <div className="more-servicepage-details">
                            <h1>More Services</h1>

                            <hr className="more-service-solid" />
                            <div className="more-service-hover">
                            </div>
                            <Link to="/Moduler"><div className="moduler-list-part">
                                <span><p>Upvc Moduler Kitchen Designs In Hosur</p></span>
                            </div></Link>

                           <Link to="/Wardobes"><div className="moduler-list-part">
                                <span><p>Upvc Wardobes Interior Designs In Hosur </p></span>
                            </div></Link> 

                           <Link to="/poojaunit"><div className="moduler-list-part ">
                                <span><p>Upvc Pooja Unit Interior Designs In Hosur</p></span>
                            </div></Link>

                           <Link to="/Tvunit"><div className="moduler-list-part">
                               <span><p>Upvc Tv Unit Interior Designs In Hosur</p></span> 
                            </div></Link> 

                           <Link to="/Dressing"><div className="moduler-list-part">
                                <span><p>Upvc Dressing Table Interior Designs In Hosur</p></span>
                            </div></Link> 

                           <Link to="/Bathroom"><div className="moduler-list-part">
                                <span><p>Upvc Bathroom Doors Interior Designs In Hosur</p></span>
                            </div></Link> 


                          <Link to="/False" ><div className="moduler-list-part bg">
                                <span><p>Upvc False Ceiling Interior Designs In Hosur</p></span>
                            </div></Link> 

                        </div>
                        {/* more service contact section */}

                        <div className="more-service-contact" >

                            <div className="more-service-contactinfo">
                                <img src="/images/monisha/contact/mob.png" />
                                <h3>Phone Number</h3>
                                <p>+097885 37772</p>

                                <p>+094433 48032</p>
                            </div>

                            <div className="more-service-contactinfo">
                                <img src="/images/monisha/contact/email-to.png" />
                                <h3>Email Address</h3>


                                <p>  madaiyanpvcinteriorhsr@gmail.com</p>
                            </div>

                            <div className="more-service-contactinfo">
                                <img src="/images/monisha/contact/end-to-end.png" />
                                <h3>Location</h3>


                                <p>  No.3 Akila Nillayam,<br />Trend City
                                    Oppsite,<br />Chithanapalli Village,<br />
                                    Nallur Post Hosur,<br />
                                    Tamilnadu 635109,India</p>
                            </div>

                        </div>
                        {/* ..................gallery section start........... */}
                        <div className="more-service-gallery">

                            <h4>Gallery</h4>
                            <hr className="more-service-solid" />
                            <div className="more-gallery-images">

                                <img src="/images/monisha/service/false/2.jpg" />
                                <img src="/images/monisha/service/false/2.webp" />
                                <img src="/images/monisha/service/false/3.webp" />
                                <img src="/images/monisha/service/false/4.jpeg" />
                                <img src="/images/monisha/service/false/6.jpg" />
                                <img src="/images/monisha/service/false/7.jpeg" />
                                
                                
                            </div>

                        </div>

                        {/* ......contact form for service page ...... */}

                        <div className="contact-form-servicevalid">
                            <h4 className="contact-form-head">Contact Now</h4>
                            <form onSubmit={handleFormSubmit}>
                                <div className="consultant-boxs">

                                    <div className="consultant-mini">

                                        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                                        <label htmlFor="name">Your Name</label>
                                    </div>
                                    <div className="consultant-mini">
                                        <input type="number" id="number" value={number} onChange={(e) => setNumber(e.target.value)} required />
                                        <label htmlFor="number">Your Number</label>
                                    </div>
                                </div>
                                <div className="consultant-min">
                                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                    <label htmlFor="email">Your Email</label>
                                </div>
                                <div className="consultant-min">
                                    <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} cols="30" rows="5" required />
                                    <label htmlFor="message">Message</label>
                                </div>
                                <button type="submit" className="btn">

                                    <span className="btn-2">Send Message</span>

                                </button>
                            </form>
                        </div>

                        {/* ..... service page right startTransition......... */}





                    </div>


                    <div className="moduler-interior-theme">
                        <img src="/images/monisha/service/false/bg.webp" />
                        <h4> uPVC  False Ceilling Interior Design in Hosur</h4>
                        <ul>
                            <li><p>Discover a new dimension in living with Monisha Interior, where the art of design converges with the essence of your lifestyle. Our specialized services are crafted to elevate your living experience, ensuring that each corner of your home resonates with your unique personality. Explore our range of design solutions, meticulously curated to redefine your living spaces with sophistication and functionality.</p></li>
                            <li><p>We can incorporate modern appliances, strategic lighting, and ventilation solutions, that are
                                essential for a comfortable kitchen. Neutral color palettes and contemporary designs are some common
                                choices for your designs. We can make your modular kitchen designs more successful, all while establishing a
                                budget-friendly approach under professionals to maximize the functionality and aesthetics of your kitchens.</p>
                            </li>  </ul>


                        <h4>uPVC False Ceilling Interior Designs</h4>
                        <h5>Design Consultation:</h5>
                        <p>Envision a ceiling that reflects your style with our expert design consultations. Monisha Interior's personalized approach ensures a false ceiling design that harmonizes with your space, capturing both beauty and functionality.</p>


                        <h5>Space Planning And Layout </h5>
                        <p>Optimize your space with meticulous planning of your false ceiling. Our designers strategically position uPVC elements for an optimal blend of functionality and visual appeal,
                             transforming your Hosur space into a statement of sophistication..</p>

                        <h5> Material Selection </h5>
                        <p>Choose from a wide range of premium kitchen countertop options, including granite,
                            quartz, and marble, expertly installed
                            to add elegance and functionality to your modular kitchen.</p>


                        <h5>Lighting Design </h5>
                        <p>Illuminate your uPVC false ceiling with brilliance through our bespoke lighting design services. From ambient glow to strategic spotlights, Monisha Interior creates an ambiance that enhances the overall allure of your space, making it a captivating environment.

</p>



                            <h5>Color Palette And Finishes </h5>
                        <p>Maximize the efficiency of your kitchen space with our innovative storage solutions,
                            such as pull-out shelves, corner units, and modular organizers, providing easy access
                            to your kitchen essentials while keeping everything neat and organized.</p>



                        {/* ......quality & why we best page create....... */}
                        <div className="quality-service-section">

                            <div className="quality-section-boxs">
                                <div className="quality-info">
                                    <h5>Quality</h5>

                                    <p>At Monisha Interiors, we prioritize exceptional quality in every aspect of
                                        our work. From material selection to project execution, we never compromise
                                        on the craftsmanship that goes into our modular kitchen interior design. To
                                        ensure the lifespan and durability of your kitchen, our qualified professionals use
                                        premium materials, pay great attention to detail, and collaborate with reliable suppliers.</p>
                                    <Link to="/"> <p> <span className="readmore">Read More</span></p></Link>
                                </div>

                                <div className="quality-info">
                                    <h5>Why We Best</h5>

                                    <p>In-depth understanding of the most recent trends, design principles,
                                        and practical aspects of Coimbatore kitchen interiors is held by our talented
                                        team of designers. Yes, we place a premium on maintaining loyal customers. To
                                        solve any worries or issues you might have with your modular kitchen, we offer
                                        thorough after-sales service and support. Your continuing happiness with our work is
                                        important to our staff.</p>


                                    <Link to="/"> <p> <span className="readmore">Read More</span></p></Link>
                                </div>

                            </div>
                        </div>

                        {/* .....lightbox effect.... */}
                        <div className="servicelight-card-area">
                            <div className="servicelight-wrapper">
                                <h4>Our Latest Project</h4>
                                <div className="servicelight-box-area">

                                    <div className="box">
                                    <img src="/images/monisha/service/false/2.jpg" />
                                        <div className="overlay">
                                            <h3>False Ceilling Interior</h3>
                                            {/* <p>kitchen Interior</p> */}

                                        </div>
                                    </div>

                                    <div className="box">
                                    <img src="/images/monisha/service/false/6.jpg" />
                                        <div className="overlay">
                                            <h3>False Ceilling Interior</h3>
                                            {/* <p>kitchen Interior</p> */}

                                        </div>
                                    </div>

                                    <div className="box">
                                    <img src="/images/monisha/service/false/4.jpeg" />
                                        <div className="overlay">
                                            <h3>Flase Ceilling Interior</h3>
                                            {/* <p>kitchen Interior</p> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ......faq  for service page.... */}



                    </div>
                </div>
            </div>
            <Footer/>

        </div>


    )
}
export default False;
