import React from "react";

import { PiTwitterLogoDuotone } from "react-icons/pi";
import { FaFacebook } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import { FaLocationDot } from "react-icons/fa6";
import { MdAddCall } from "react-icons/md";
import { AiFillMessage } from "react-icons/ai";
import { FaClock } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import {Link } from 'react-router-dom';
import './Footer.css';
import Tirupathur from './../Tirupathur/Tirupathur';
function Footer(){
    return(
       
        <div className="footer">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-lg-3">
                    <div className="footer-contact">
                        <h3>About Us</h3>
                        <p>
                        The interior in Hosur is by Monisha Interior. We have many years of experience and more satisfied clients.</p>
                        <div className="footer-social">
                            <a href="#"><PiTwitterLogoDuotone className="footer-icon"/></a>
                            <a href="https://www.facebook.com/profile.php?id=61555185850636" target="_blank"><FaFacebook className="footer-icon"/></a>
                            <a href="#"><FiYoutube className="footer-icon"/></a>
                            <a href="https://www.instagram.com/monisha_upvc_pvc_interiors/" target="_blank"><FaInstagram className="footer-icon"/></a>
                            <a href="#"><CiLinkedin className="footer-icon"/></a>
                        </div>
                        <p className="mt-3"><MdAddCall className="footer-icon"/><span>+097885 37772<br/>+094433 48032</span></p>
                        <p className="mt-3"><AiFillMessage className="footer-icon"/><span>madaiyanpvcinteriorhsr@gmail.com</span></p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="footer-contact">
                        <h3>Our Branches</h3>
                        <p><FaLocationDot className="footer-icon"/><span>No.3 Akila Nillayam,Trend City
                                      Oppsite,Chithanapalli Village, 
                                     Nallur Post Hosur,
                                  Tamilnadu 635109,India</span></p>
                                  <p><FaLocationDot className="footer-icon"/><span>101,Balaganapathi, nilaya,Nethravathi tent road,
Near to Shyam Ambika
apartments,Yelachenahalli,
Jp Nagar Post
Bangalore Karnataka 560078</span></p>
                                  <p><FaLocationDot className="footer-icon"/><span>No.34/23,Periyar Street,
                                      PaadiKuppam,Ambathur
                                      Koyambedu,
                                     Chennai
                                  Tamilnadu 600107,India</span></p>
                       
                        {/* <p><FaClock className="footer-icon"/><span>Mon - Fri, 9AM - 10PM</span></p> */}
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="footer-links">
                        
                       <h3>Covered Areas</h3>
                       <span><Link to="/Banglore"><FaArrowRight className="footer-icon"/>Bangalore</Link></span>
                        {/* <Link to="about"><FaArrowRight className="footer-icon"/>Contact</Link> */}
                        <span><Link to="/Hydrabad"><FaArrowRight className="footer-icon"/>Hyderabad</Link></span>
                        {/* <Link to="about"></Link><FaArrowRight className="footer-icon"/>Project</a> */}
                        <span><Link to="/Chennai"><FaArrowRight className="footer-icon"/>Chennai</Link></span>
                       <span><Link to="/Vellore"><FaArrowRight className="footer-icon"/>Vellore</Link></span> 
                       <span><Link to="/Tirupathur"><FaArrowRight className="footer-icon"/>Tirupathur</Link></span> 
                       <span><Link to="/Tiruvannamalai"><FaArrowRight className="footer-icon"/>Tiruvannamalai</Link></span> 
                       <span><Link to="/Dharmapuri"><FaArrowRight className="footer-icon"/>Dharmapuri</Link></span> 
                    </div>
                </div>


                
                <div className="col-md-6 col-lg-3">
                    <div className="footer-project">
                        <h3>Latest Projects</h3>
                        <Link to="/Bathroom"><img src="/images/monisha/service/bathroom/2.jpg" alt="Image"/></Link>
                        <Link to="/False"><img src="/images/monisha/service/false/6.jpg" alt="Image"/></Link>
                        <Link to="/Dressing"><img src="/images/monisha/service/dressing/4.webp" alt="Image"/></Link>
                        <Link to="/Service"><img src="/images/monisha/service/moduler/4.webp" alt="Image"/></Link>
                        <Link to="/Service"><img src="/images/monisha/service/moduler/6.jpg" alt="Image"/></Link>
                        <Link to="/Poojaunit"><img src="/images/monisha/service/poojaunit/1.jpg"alt="Image"/></Link>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="container footer-newsletter">
            <p>
                Lorem ipsum dolor sit amet elit. Quisque eu lectus a leo dictum nec non quam. Tortor eu placerat rhoncus, lorem quam iaculis felis, sed lacus neque id eros 
            </p>
            <div className="row form">
                <div className="col-sm-4">
                    <input className="form-control" placeholder="Your Name">
                </div>
                <div className="col-sm-4">
                    <input className="form-control" placeholder="Your Email">
                </div>
                <div className="col-sm-4">
                    <button className="btn">Subscribe</button>
                </div>
            </div>
        </div> */}
        <div className="copyright">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="copy-text">
                            <p>&copy; <a href="#">HoneyIconics</a>. All Rights Reserved</p>
                        </div>
                    </div>
                     <div className="col-md-6">
                        <div className="copy-menu">
                            <a href="/">Home</a>
                            <a href="/about-us">About</a>
                            <a href="/Service">Services</a>
                            <a href="/Project">Project</a>
                            
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
   
    )
}
export default Footer;