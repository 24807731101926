import React, { useState } from "react";
import './Dressing.css';
import { Link } from 'react-router-dom';
import { CiHome } from "react-icons/ci";
import Footer from "../../Footer/Footer";
import { Helmet } from 'react-helmet';
// import Cardhover from "../Cardhover";
//import Aboutfaq2 from "../Aboutfaq2";

const Dressing = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (name === '' || email === '' || number === '' || message === '') {
            alert("Please fill in all fields");
        } else {
            const formattedMessage = encodeURIComponent(
                "Contact Page info" + "\n" +
                " Name : " + name + "\n" +
                " Email : " + email + "\n" +
                " Mobile Number : " + "+" + number + "\n" +
                " Message : " + message
            );

            const url = "https://wa.me/+919788537772?text=" + formattedMessage;
            window.open(url, '_blank');
        }
    };

    return (
        <div>
            <Helmet>
        <title> Uplift Your Space with uPVC Dressing Table Interior Design in Hosur
</title>
        <meta name="description" content="Monisha Interior brings a touch of elegance to
         your Hosur home with our specialized uPVC dressing table interior design services.
          From personalized design consultations to expert space planning, captivating lighting
           design, and curated color palette selections, our comprehensive services redefine 
           the art of dressing table aesthetics. Transform your personal space into a haven of
            style and functionality with Monisha Interior's unparalleled expertise.
"

/>
       
        {/* Other meta tags, link tags, etc. */}
      </Helmet>
            {/* service banner start.... */}
            <section className="about-banner">
                <div className="about-banner-images">
                    <img src="/images/monisha/4.jpeg" />

                    <div className="about-content">
                        <h1>Dressing Table Interior</h1>
                    </div>
                    <div className="about-banner-nav">
                        <div className="aboutNav">
                        <CiHome className='some' /> <Link to="/" ><span>Home</span> </Link> / Dressing
                        </div>

                    </div>
                </div>
            </section>

            {/* .....................banner end.......... */}

            {/* service left page start.. */}
            <div className="more-servicepage-section">
                <div className="more-servicepage-container container">
                    <div className="more-servicepage-boxes">


                        <div className="more-servicepage-details">
                            <h1>More Services</h1>

                            <hr className="more-service-solid" />
                            <div className="more-service-hover">
                            </div>
                            <Link to="/Moduler"><div className="moduler-list-part">
                                <span><p>Upvc Moduler Kitchen Designs In Hosur</p></span>
                            </div></Link>

                           <Link to="/Wardobes"><div className="moduler-list-part">
                                <span><p>Upvc Wardobes Interior Designs In Hosur </p></span>
                            </div></Link> 

                           <Link to="/poojaunit"><div className="moduler-list-part">
                                <span><p>Upvc Pooja Unit Interior Designs In Hosur</p></span>
                            </div></Link>

                           <Link to="/Tvunit"><div className="moduler-list-part ">
                               <span><p>Upvc Tv Unit Interior Designs In Hosur</p></span> 
                            </div></Link> 

                           <Link to="/Dressing"><div className="moduler-list-part bg">
                                <span><p>Upvc Dressing Table Interior Designs In Hosur</p></span>
                            </div></Link> 

                           <Link to="/Bathroom"><div className="moduler-list-part">
                                <span><p>Upvc Bathroom Doors Interior Designs In Hosur</p></span>
                            </div></Link> 


                          <Link to="/False" ><div className="moduler-list-part">
                                <span><p>Upvc False Ceiling Interior Designs In Hosur</p></span>
                            </div></Link> 

                        </div>
                        {/* more service contact section */}

                        <div className="more-service-contact" >

                            <div className="more-service-contactinfo">
                                <img src="/images/monisha/contact/mob.png" />
                                <h3>Phone Number</h3>
                                <p>+097885 37772</p>

                                <p>+094433 48032</p>
                            </div>

                            <div className="more-service-contactinfo">
                                <img src="/images/monisha/contact/email-to.png" />
                                <h3>Email Address</h3>


                                <p>  madaiyanpvcinteriorhsr@gmail.com</p>
                            </div>

                            <div className="more-service-contactinfo">
                                <img src="/images/monisha/contact/end-to-end.png" />
                                <h3>Location</h3>


                                <p>  No.3 Akila Nillayam,<br />Trend City
                                    Oppsite,<br />Chithanapalli Village,<br />
                                    Nallur Post Hosur,<br />
                                    Tamilnadu 635109,India</p>
                            </div>

                        </div>
                        {/* ..................gallery section start........... */}
                        <div className="more-service-gallery">

                            <h4>Gallery</h4>
                            <hr className="more-service-solid" />
                            <div className="more-gallery-images">

                                <img src="/images/monisha/service/dressing/8.jpg" />
                                <img src="/images/monisha/service/dressing/3.webp" />
                                <img src="/images/monisha/service/dressing/4.webp" />
                                <img src="/images/monisha/service/dressing/5.jpg" />
                                <img src="/images/monisha/service/dressing/6.webp" />
                                <img src="/images/monisha/service/dressing/7.jpg" />
                            </div>

                        </div>

                        {/* ......contact form for service page ...... */}

                        <div className="contact-form-servicevalid">
                            <h4 className="contact-form-head">Contact Now</h4>
                            <form onSubmit={handleFormSubmit}>
                                <div className="consultant-boxs">

                                    <div className="consultant-mini">

                                        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                                        <label htmlFor="name">Your Name</label>
                                    </div>
                                    <div className="consultant-mini">
                                        <input type="number" id="number" value={number} onChange={(e) => setNumber(e.target.value)} required />
                                        <label htmlFor="number">Your Number</label>
                                    </div>
                                </div>
                                <div className="consultant-min">
                                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                    <label htmlFor="email">Your Email</label>
                                </div>
                                <div className="consultant-min">
                                    <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} cols="30" rows="5" required />
                                    <label htmlFor="message">Message</label>
                                </div>
                                <button type="submit" className="btn">

                                    <span className="btn-2">Send Message</span>

                                </button>
                            </form>
                        </div>

                        {/* ..... service page right startTransition......... */}





                    </div>


                    <div className="moduler-interior-theme">
                        <img src="/images/monisha/service/dressing/1.jpg" />
                        <h4> uPVC  Dressing Table Interior Design in Hosur</h4>
                        <ul>
                            <li><p> Discover a new dimension in living with Monisha Interior, where the art of design 
                                converges with the essence of your lifestyle. Our specialized services are crafted to 
                                elevate your living experience, ensuring that each corner of your home resonates with
                                 your unique personality. Explore our range of design solutions, meticulously curated to 
                                redefine your living spaces with sophistication and functionality.</p></li>
                            <li><p>We can incorporate modern appliances, strategic lighting, and ventilation solutions, that are
                                essential for a comfortable kitchen. Neutral color palettes and contemporary designs are some common
                                choices for your designs. We can make your modular kitchen designs more successful, all while establishing a
                                budget-friendly approach under professionals to maximize the functionality and aesthetics of your kitchens.</p>
                            </li>  </ul>


                        <h4>uPVC Dressing Table Interior Designs</h4>
                        <h5>Design Consultation:</h5>
                        <p>Elevate your daily routine with our expert design consultations, 
                            tailored to enhance the aesthetics and functionality of your uPVC dressing table. 
                            Monisha Interior crafts personalized solutions that align with your preferences,
                             making your dressing area a reflection of your unique style..</p>


                        <h5>Space Planning And Layout </h5>
                        <p>Optimize your dressing space with meticulous space planning and layout services. 
                            Our designers analyze your room, strategically placing uPVC dressing tables for an optimal
                             blend of functionality and
                             visual appeal, ensuring a harmonious atmosphere in your Hosur home.</p>

                        <h5>Lighting Design: </h5>
                        <p>Illuminate your uPVC dressing table with brilliance through our 
                            bespoke lighting design services. From flattering vanity lights to
                             ambient illumination, Monisha Interior creates a captivating ambiance that enhances the overall
                             allure of your dressing area, making it a delightful space to prepare for your day.</p>


                        



                            <h5>Color Palette And Finishes </h5>
                        <p>Infuse personality into your uPVC dressing table with 
                            our curated color palettes and finishes. Our experts guide 
                            you through a spectrum of colors, textures, and finishes, helping you 
                            choose the ideal combination that complements 
                            your style and enhances the aesthetic appeal of your dressing space.</p>



                        {/* ......quality & why we best page create....... */}
                        <div className="quality-service-section">

                            <div className="quality-section-boxs">
                                <div className="quality-info">
                                    <h5>Quality</h5>

                                    <p>Monisha Interior sets the standard for interior design quality. With a keen
                                         eye for premium materials and meticulous craftsmanship, we create 
                                         spaces that stand the test of time. Experience sophistication and durability 
                                         harmoniously woven into every detail. Your home, our commitment to uncompromising quality.</p>
                                    <Link to="/"> <p> <span className="readmore">Read More</span></p></Link>
                                </div>

                                <div className="quality-info">
                                    <h5>Why We Best</h5>

                                    <p>We stand as the best because Monisha Interior 
                                        combines visionary design with unparalleled craftsmanship.
                                         Our commitment to client satisfaction, attention to detail, and 
                                         use of premium materials set us apart. With a passion for creating 
                                         timeless and functional spaces, we turn your dreams into reality. Choose 
                                        us for an unmatched blend of creativity, quality, and personalized excellence.</p>
                                    


                                    <Link to="/"> <p> <span className="readmore">Read More</span></p></Link>
                                </div>

                            </div>
                        </div>

                        {/* .....lightbox effect.... */}
                        <div className="servicelight-card-area">
                            <div className="servicelight-wrapper">
                                <h4>Our Latest Project</h4>
                                <div className="servicelight-box-area">

                                    <div className="box">
                                    <img src="/images/monisha/service/dressing/3.webp" />
                                        <div className="overlay">
                                            <h3>Dressing Table Interior</h3>
                                            {/* <p>kitchen Interior</p> */}

                                        </div>
                                    </div>

                                    <div className="box">
                                    <img src="/images/monisha/service/dressing/8.jpg" />
                                        <div className="overlay">
                                            <h3>Dressing Table Interior</h3>
                                            {/* <p>kitchen Interior</p> */}

                                        </div>
                                    </div>

                                    <div className="box">
                                    <img src="/images/monisha/service/dressing/7.jpg" />
                                        <div className="overlay">
                                            <h3>Tv Unit Interior</h3>
                                            {/* <p>kitchen Interior</p> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ......faq  for service page.... */}



                    </div>
                </div>
            </div>
            <Footer/>

        </div>


    )
}
export default Dressing;
