import React, { useState } from "react";
import './Tiruvannamalai.css';
import { Link } from 'react-router-dom';
import Footer from "../Footer/Footer";
import { Helmet } from 'react-helmet';

const Tiruvannamalai = () => {
    const [activeQuestion, setActiveQuestion] = useState(null);

    const handleQuestionClick = (index) => {
        setActiveQuestion((prevActiveQuestion) => (prevActiveQuestion === index ? null : index));
    };

    const faqData = [
        // ... (your FAQ data remains unchanged)
        {
            question: "What distinguishes Tiruvannamalai-based Monisha Interiors from other interior designers?",
            answer: "Monisha Interiors' distinctive style is centred on bespoke designs that perfectly balance comfort and flair, embodying the essence of Tiruvannamalai life.",
        },
        {
            question: "What is the technique Monisha Interiors uses for designing a client's space in Tiruvannamalai?",
            answer: "In order to develop designs that are in line with Tiruvannamalai's dynamic lifestyle, we work closely with our customers throughout the design process, getting to know their tastes and adding local influences.",
        },
        {
            question: "Can Monisha Interiors oversee projects that are both residential and commercial in Tiruvannamalai?",
            answer: "Completely! We provide specialized solutions that address the various needs of our clients, specializing in both residential and commercial interior design projects in Tiruvannamalai.",
        },
        {
            question: "How does Tiruvannamalai-based Monisha Interiors integrate sustainability into their interior designs?",
            answer: "One of our main priorities is sustainability. Our interior designs in Tiruvannamalai focus eco-friendly materials, use sustainable techniques, and source eco-friendly resources to ensure that our work contributes to a greener world.",

        },
        {
            question: "Is Monisha Interiors able to manage projects in Tiruvannamalai of varying sizes?",
            answer: "Definitely! Our team of skilled interior designers in Tiruvannamalai can handle projects of all sizes, from little apartments to large commercial spaces, guaranteeing unique and outstanding results.",

        },
        {
            question: "What is the typical timeline for completing an interior design project in Tiruvannamalai?",
            answer: "Monisha Interior provides expert care in orthopedic and neurological physiotherapy. Our services include comprehensive assessments, personalized treatment plans, and rehabilitation programs tailored to your unique needs.",

        },

    ];







    return (
        <div>
        <Helmet>
        <title>Elevating Spaces in Thiruvannamalai, Interior in Thiruvannamalai</title>
        <meta name="description" 
        content="Transform your living or commercial space with Monisha Interior in Thiruvannamalai. Expert interior design services are tailored to your needs, enhancing aesthetics and functionality.
        "/>


       
        {/* Other meta tags, link tags, etc. */}
      </Helmet>
      <div className="video-container">
        <video
          src="/images/monisha/SALA.mp4"

          autoPlay
          loop
          muted
          className="video-element"




        />

        <div className="video-info">
          <div class="vl-banner-video"></div> 

          <div className="video-banner-paragraph">
            <h1 className="video-head">Interior Designers in Tiruvannamalai</h1>
            <p className="video-para"> <p className="carousel-para">Welcome to a realm of design where your dreams meet our expertise, and together,
              we create spaces that resonate with your soul</p></p>
            <Link to="/Contact-us"> <button className="video-button">Contact Us</button> </Link>
          </div>
        </div>
      </div>
            {/* //  .........about page...... */}
            <div className="about-page">


                {/*.......... about us and iframe .....................*/}
                <div className="aboutus-page ">
                    <div className="aboutus-container container">
                        <div className="aboutus-boxes">
                            <div className="aboutus-image">
                                <img src="images/monisha/home/3.jpeg" />
                            </div>
                            <div className="abouts-para">
                                <h2 className="who-we-are heading">WHO WE ARE</h2>
                                <h1>Best Interior Designers In Tiruvannamalai - Monisha Interiors</h1>
                                <p>We painstakingly create spaces that combine style and utility from conception to completion
                                    . To ensure a custom design that meets your demands, our method entails getting to know your
                                     tastes and way of life. Being the top interior designers in Tiruvannamalai, we place a premium on high-caliber
                                      components, creative designs, and prompt completion. With a focus on comfort and elegance,
                                       Monisha Interiors takes pleasure in producing homes that not only live up to but surpass expectations.Our
                                        skilled team brings visions to life with an unwavering attention to detail and a creative spirit. 
                                        Enhance your quality of life with Monisha Interiors, where exceptional craftsmanship and interior
                                         design collide. With the help of Monisha Interiors, the finest 
                                    interior designers in Tiruvannamalai, experience the real meaning of luxurious living.</p>

                                <button className="aboutus-button">About Us</button>


                                {/* ......................who we are start............................. */}
                                <div className="whoweare-service">

                                    <div className="whoweare-service-icons">
                                        <img src="images/monisha/home/price2.png" />
                                        <p>3 Yrs Free Service</p>
                                    </div>

                                    <div className="whoweare-service-icons">
                                        <img src="images/monisha/home/design2.png" />
                                        <p> 15 Years Warranty</p>
                                    </div>

                                    <div className="whoweare-service-icons">
                                        <img src="images/monisha/home/warranty2.png" />
                                        <p>Transparent pricing</p>
                                    </div>


                                </div>

                            </div>

                        </div>

                    </div>

                </div>






            </div>
            {/* .......... who we are page end........ */}
            {/* .....Cardhover page start........... */}
            <div>
                <div className="servicecard-hover-para">

                    <div className="card-hover-divider">OUR SERVICES</div>

                    <h3>What Interior Designers Do</h3>

                    <div className="servicecard-hover-container container">

                        <div className="servicecard-hover-box">
                            <div className="servicecard-hover-img">
                            <img src="/images/monisha/service/moduler/blue.webp" height="350px" width="350px" />
                            </div>
                            <div className="overlay"></div>
                            <div className="overlay2"></div>
                            <div className="text">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <h2>Moduler Kitchen</h2>
                                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
                            </div>
                        </div>
                        <div className="servicecard-hover-box">
                            <div className="servicecard-hover-img2">
                                <img src="/images/monisha/project/43.jpg" height="350px" width="350px" />
                            </div>
                            <div className="overlay"></div>
                            <div className="overlay2"></div>
                            <div className="text">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <h2>Wardobe</h2>
                                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
                            </div>
                        </div>
                        <div className="servicecard-hover-box">
                            <div className="servicecard-hover-img3">
                                <img src="/images/monisha/service/poojaunit.jpg" height="350px" width="350px" />
                            </div>
                            <div className="overlay"></div>
                            <div className="overlay2"></div>
                            <div className="text">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <h2>Pooja Unit</h2>
                                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
                            </div>
                        </div>
                        <div className="servicecard-hover-box">
                            <div className="servicecard-hover-img4">
                                <img src="/images/monisha/service/tv unit.webp" height="350px" width="350px" />
                            </div>
                            <div className="overlay"></div>
                            <div className="overlay2"></div>
                            <div className="text">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <h2>Tv Unit</h2>
                                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
                            </div>
                        </div>

                        <br />


                    </div>
                </div>
            </div>
            {/* ............card hover page end.........         */}

            {/* ..................card hover 2 page start..... */}

            <div className="servicecard-hover-container container">

                <div className="servicecard-hover-box">
                    <div className="servicecard-hover-img">
                        <img src="/images/monisha/service/dressing table.jpg" height="350px" width="350px" />
                    </div>
                    <div className="overlay"></div>
                    <div className="overlay2"></div>
                    <div className="text">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <h2>Dressing Table</h2>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
                    </div>
                </div>
                <div className="servicecard-hover-box">
                    <div className="servicecard-hover-img2">
                        <img src="/images/monisha/service/bathroom2.webp" height="350px" width="350px" />
                    </div>
                    <div className="overlay"></div>
                    <div className="overlay2"></div>
                    <div className="text">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <h2>Bathroom Doors</h2>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
                    </div>
                </div>
                <div className="servicecard-hover-box">
                    <div className="servicecard-hover-img3">
                        <img src="/images/monisha/service/false.webp" height="350px" width="350px" />
                    </div>
                    <div className="overlay"></div>
                    <div className="overlay2"></div>
                    <div className="text">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <h2>False Ceilling</h2>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
                    </div>
                </div>
                <div className="servicecard-hover-box">
                    <div className="servicecard-hover-img4">
                    <img src="/images/monisha/service/moduler/cpu.jpg" height="350px" width="350px"/>
                    </div>
                    <div className="overlay"></div>
                    <div className="overlay2"></div>
                    <div className="text">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <h2>Table Unit</h2>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
                    </div>
                </div>


                <br />


            </div>
            {/* ......profeesional page start.... */}
            {/* //  .........about page...... */}



            {/*.......... about us and iframe .....................*/}
            <div className="aboutus-page ">
                <div className="aboutus-container container">
                    <div className="aboutus-boxes">
                        
                        <div className="abouts-para">
                            <h2 className="who-we-aree heading">Monisha Interior</h2>
                            <h1>Interiors in Tiruvannamalai</h1>
                            <h6 className="professional">100% Customized Interiors By Professionals </h6>
                            <p>Improve your living areas with the help of our company's top interior designers in Tiruvannamalai. We are proud to provide 100% personalized interiors created by skilled professionals at our well-known interior design studio. We approach each project with an unwavering focus on creating spaces that are true to your own vision and with painstaking attention to detail. We deliver the ideal blend of originality and practicality to every project as the top interior designers in Tiruvannamalai. From idea to completion, our experts are committed to producing outstanding outcomes that go above and beyond.Experience the unmatched happiness of having a house built specifically for you. Our dedication to quality and enthusiasm for creativity distinguish us as the top option for individuals looking for the best interior designers in Tiruvannamalai. With the help of our staff, discover the pinnacle of personalized luxury, where your ideal interior design becomes a reality.</p>


                            {/* <button className="aboutus-button">About Us</button> */}
                        </div>

                        <div className="aboutus-image">
                            <img src="images/monisha/bg.jpg" />
                        </div>
                        </div>
                    </div>
                </div>
               
                {/* ..............why choose me page start........... */}
                <div className="about-provides">

                    <div className="about-provide-container container">
                        <div className="about-provide-title">

                            <div className="about-provide-divider"> <h1>Why Choose Me?</h1> </div>
                        </div>
                        <div className="about-provide-boxs">

                            <div className="we-provide">
                                <div className="weprovideimage-round">
                                    <img className="we-provide-image" src="images/monisha/home/warrenty.png" />
                                </div>
                                <p><h6>15 Year Warranty</h6>Good performance and reliable quality products with no room for flaws and defects.</p>
                            </div>


                            <div className="we-provide">
                                <div className="weprovideimage-round">
                                    <img className="we-provide-image" src="images/monisha/home/headphone.png" />
                                </div>
                                <p><h6> Consultation</h6>a friendly consultation is all it takes to brighten someone's day and lighten their load.</p>
                            </div>


                            <div className="we-provide">
                                <div className="weprovideimage-round">
                                    <img className="we-provide-image" src="images/monisha/home/key.png" />
                                </div>
                                <p><h6>Under 20-Days Delivery</h6>2 BHK kitchen+Dinning+Bedroom     <br/>Duplex 20 + 10 Days</p>
                            </div>




                            {/* we provide second line */}


                            <div className="we-provide">
                                <div className="weprovideimage-round">
                                    <img className="we-provide-image" src="images/monisha/home/bulb.png" />
                                </div>
                                <p><h6>Unique Design</h6>Irreplaceable unique designs to suit everyone’s palette with the expertise of our professionals</p>
                            </div>


                            <div className="we-provide">
                                <div className="weprovideimage-round">
                                    <img className="we-provide-image" src="images/monisha/home/3d design.png" />
                                </div>
                                <p><h6>Live 3d Design</h6>Quick and accurate visualization for your home interiors.</p>
                            </div>


                            <div className="we-provide">
                                <div className="weprovideimage-round">
                                    <img className="we-provide-image" src="images/monisha/home/certificate.png" />
                                </div>
                                <p><h6>Premium Quality Products</h6>Quality products in terms of cost and durability.</p>
                            </div>
                        </div>




                    </div>


                </div>

                {/* ..................why choose me page end............ */}
                {/* ........calling us page start....... */}
                <div className="callingus-section">
                    <div className="callingus-container container">
                        <div className="callingus-para">
                            <p><h1 className="callingus-para-head">Need help deciding where to begin?</h1>
                                <h2 className="callingus-para-subhead">GROW YOUR LIFE BY CALLING US.</h2>
                                <h3 className="callingus-para-sprhead">Monisha Interior Designer says that interior design should be within everyone's reach.</h3>
                                <a href="tel:+917373730340"> <h4 className="callingus-para-prohead">
                                    Call - +91 7373730340
                                </h4></a>
                            </p>

                        </div>
                    </div>
                </div>

                {/* ......about service page start......... */}
                {/* ..................home page /service page start.............. */}
                <div>
                    <div className="how-wedoit-section">
                        <div className="how-wedoit-container ">
                            <div className="how-wedoit-boxs">

                                {/* <div className="home-service-image">
              <img src="images/monisha/home/howwedoit.webp" width="600px" height="782px" />
            </div> */}

                                <div className="how-wedoit-para">
                                    <div className="how-wedoitpara-container container">


                                        <h1 className="how-wedoitpara-head">Interior Desiners In Tiruvannamalai</h1>
                                        <h2 className="how-wedoitpara-subhead"> <div class="divider">HERE IS HOW WE DO IT</div></h2>
                                        <h3>Steps to Interior Design in Tiruvannamalai</h3>

                                        <div className="how-wedoit-dediv">
                                            <div className="how-wedoit-details">
                                                <img src="images/monisha/home/planning.png" alt="Your Image" class="rotate-y" />
                                                <h1>Strategic Planning</h1>
                                                <p>The greatest interior designers in Tiruvannamalai can help you live a better life by
                                                     providing strategic planning that 
                                                    will guarantee a harmonious and fashionable makeover of your house.</p>
                                            </div>


                                            <div className="how-wedoit-details">
                                                <img src="images/monisha/home/concept.png" />
                                                <h1>Developing Concepts</h1>
                                                <p>Discover imaginative design with Tiruvannamalai's top interior designers; we specialize
                                                     in creating ideas that bring 
                                                    your ideal home to life, precisely tailored to your specifications.</p>
                                            </div>

                                            <div className="how-wedoit-details">
                                                <img src="images/monisha/home/designdevelopment.png" />
                                                <h1>Design Development</h1>
                                                <p>As the top interior designers in Tiruvannamalai, we are skilled at turning ideas into reality 
                                                    and making sure your house exudes unparalleled style and practicality.</p>
                                            </div>


                                            <div className="how-wedoit-details">
                                                <img src="images/monisha/home/construction.png" />
                                                <h1>Construction Work</h1>

                                                <p>As the top interior designers in Tiruvannamalai, we can precisely and superbly elevate your living areas while providing a smooth 
                                                    transition between the design and building phases of your ideal house.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="home-service-image">
                                    <img src="images/monisha/home/howwedoit.webp" width="600px" height="738px" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* .................home service page end........... */}
                </div>

                {/* ................about faq2 page start.............. */}
                <div className="container according-wraper">
                    <h1>FAQs About Monisha Interior</h1>

                    <div className="">
                        {faqData.map((faq, index) => (
                            <div key={index} className={`accoeden-con ${index === activeQuestion ? "active" : ""}`}>
                                <div className="question" onClick={() => handleQuestionClick(index)}>
                                    {faq.question}
                                </div>
                                <div className="answercont" style={{ maxHeight: index === activeQuestion ? "100%" : "0" }}>
                                    <div className="answer">{faq.answer}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>








                <Footer/>

            </div>






            )
}

            export default Tiruvannamalai;
