import React from "react";
import'./Callingus.css';
function Callingus(){
    return(
          <div className="callingus-section">
            <div className="callingus-container container">
                <div className="callingus-para">
                    <p><h1 className="callingus-para-head">Do you need help choosing where to start?</h1>
                    <h2 className="callingus-para-subhead">TELL US ABOUT YOUR LIFE TO GROW.</h2>
                    <h3 className="callingus-para-sprhead">Embark on a transformative journey with Monisha Interior.</h3>
                    <h4 className="callingus-para-prohead">
                    Call - +91 7373730340
                    </h4>
                    </p>
                    
                </div>
            </div>
          </div>
    )
}
export default Callingus;