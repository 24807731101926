import React, { useState } from "react";
import './Contact.css';

import { Link } from 'react-router-dom';
import { CiHome } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { GoGoal } from "react-icons/go";
import { FaRegEye } from "react-icons/fa6";
import Footer from "../Footer/Footer";


// import aboutBanner from '../../src/assests/images/monisha/4.jpeg';
const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (name === '' || email === '' || number === '' || message === '') {
      alert("Please fill in all fields");
    } else {
      const formattedMessage = encodeURIComponent(
        "Contact Page info" + "\n" +
        " Name : " + name + "\n" +
        " Email : " + email + "\n" +
        " Mobile Number : " + "+" + number + "\n" +
        " Message : " + message
      );

      const url = "https://wa.me/+919788537772?text=" + formattedMessage;
      window.open(url, '_blank');
    }
  };
  return (

    <div>
      {/* ..project banner start.... */}
      <section className="about-banner">
        <div className="about-banner-images">
          <img src="/images/monisha/4.jpeg" />

          <div className="about-content">
            <h1>Contact</h1>
          </div>
          <div className="about-banner-nav">
            <div className="aboutNav">
            <Link to="/Project" ><span>Project /</span>  </Link> <CiHome className='some' />Contact
            </div>

          </div>
        </div>
      </section>


      {/* ......contact info page create....... */}
      <div className="contact-section">
        <div className="contact-section-container container">
          <div className="contact-section-boxs">
            <div className="contact-address">

              <div className="contact-address-info">
                <img src="/images/monisha/contact/end-to-end.png" />
                <h2>Office Address</h2>
                <p>No.3 Akila Nillayam,Trend City<br />
                  Oppsite,Chithanapalli Village,<br />
                  Nallur Post Hosur,<br />
                  Tamilnadu 635109,India
                </p>

              </div>
              <div class="vertical"></div>

              <div className="contact-address-info">
                <img src="/images/monisha/contact/mob.png" />
                <h2>Contact Number</h2>
                <p> <span className="phone">phone :</span>+097885 37772 </p>
                <p>  <span className="phone">phone :</span> +094433 48032 </p>


              </div>

              <div className="contact-address-info">
                <img src="/images/monisha/contact/end-to-end.png" />
                <h2>Factory</h2>
                <p>Vadamadurai (po),<br />
                  Coimbatore- 641 017
                </p>
              </div>

              <div className="contact-address-info">
                <img src="/images/monisha/contact/email-to.png" />
                <h2>Email Address</h2>
                <p>
                  madaiyanpvcinteriorhsr@gmail.com

                </p>
              </div>

            </div>
            <div className="contact-info-hour">
              <div className="contact-hour-detail">
                <h3>Working Hour</h3>
                <p>Monday - Saturday :9.30 AM to 6.00 PM</p>
                <p> <span className="sunday">Sunday Close</span></p>
                <h3>Ready To Work Us ?</h3>
                <p className="work-us-para">Transform your living spaces into stunning showcases of
                  style and functionality with the expert touch of our passionate
                  interior designers.</p>
                <p className="work-us-para">we believe that a well-designed space has the power to transform not just the
                  physical environment but also the way you live, work, and experience life. </p>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* .....map section and form section.... */}
      <div className="contact-bg-image">
        <img src="/images/monisha/contact/bg3.jpg" width="100%" />
      </div>

      <div className="contact-map-section">
        <div className="contact-map-container container">
          <div className="contact-map-boxs">
            <div className="contact-map-location">
              <iframe
                title="Google Maps Embed"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15564.75862349866!2d77.8344926!3d12.7661922!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae712689d25c1f%3A0xa3cfc4b9ba8f1b9e!2sMonisha%20upvc%20pvc%20interiors%20in%20hosur!5e0!3m2!1sen!2sin!4v1706133483748!5m2!1sen!2sin"

                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>

            {/* ......contact form.... */}
            <div className="contact-form-valid">
              <h4 className="contact-form-head">Contact Us</h4>
              <form onSubmit={handleFormSubmit}>
                <div className="consultant-boxs">

                  <div className="consultant-mini">

                    <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                    <label htmlFor="name">Your Name</label>
                  </div>
                  <div className="consultant-mini">
                    <input type="number" id="number" value={number} onChange={(e) => setNumber(e.target.value)} required />
                    <label htmlFor="number">Your Number</label>
                  </div>
                </div>
                <div className="consultant-min">
                  <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  <label htmlFor="email">Your Email</label>
                </div>
                <div className="consultant-min">
                  <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} cols="30" rows="5" required />
                  <label htmlFor="message">Message</label>
                </div>
                <button type="submit" className="btn">

                  <span className="btn-2">Submit Message</span>

                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ........contact form end.... */}
      <Footer/>
    </div>
  )
}
export default Contact;