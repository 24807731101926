import React  ,{ useState }from "react";

function Cardhover() {
  
  return (
    <div>
      <div className="servicecard-hover-para">

        <div className="card-hover-divider">OUR SERVICES</div>

        <h3>What Interior Designers Do</h3>

        <div className="servicecard-hover-container container">
       
                

          <div className="servicecard-hover-box">
            <div className="servicecard-hover-img">
              <img src="/images/monisha/service/moduler/blue.webp" height="350px" width="350px" />
            </div>
            <div className="overlay"></div>
            <div className="overlay2"></div>
            <div className="text">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <h2>Moduler Kitchen </h2>
              {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
            </div>
          </div>
          <div className="servicecard-hover-box">
            <div className="servicecard-hover-img2">
              <img src="/images/monisha/project/43.jpg" height="350px" width="350px" />
            </div>
            <div className="overlay"></div>
            <div className="overlay2"></div>
            <div className="text">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <h2>Wardobe</h2>
              {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
            </div>
          </div>
          <div className="servicecard-hover-box">
            <div className="servicecard-hover-img3">
              <img src="/images/monisha/service/poojaunit.jpg" height="350px" width="350px" />
            </div>
            <div className="overlay"></div>
            <div className="overlay2"></div>
            <div className="text">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <h2>Pooja Unit</h2>
              {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
            </div>
          </div>
          <div className="servicecard-hover-box">
            <div className="servicecard-hover-img4">
              <img src="/images/monisha/service/tv unit.webp" height="350px" width="350px" />
            </div>
            <div className="overlay"></div>
            <div className="overlay2"></div>
            <div className="text">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <h2>Tv Unit</h2>
              {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>  */}
            </div>
          </div>

          <br />


        </div>
      </div>
      </div>
   
  )
}
export default Cardhover;