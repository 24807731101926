import React, { useState } from "react";
import './Service.css';
import { Link } from 'react-router-dom';
import { CiHome } from "react-icons/ci";
import Footer from "../Footer/Footer";
// import Cardhover from "../Cardhover";
//import Aboutfaq2 from "../Aboutfaq2";
import { Helmet } from 'react-helmet';



const Service = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (name === '' || email === '' || number === '' || message === '') {
      alert("Please fill in all fields");
    } else {
      const formattedMessage = encodeURIComponent(
        "Contact Page info" + "\n" +
        " Name : " + name + "\n" +
        " Email : " + email + "\n" +
        " Mobile Number : " + "+" + number + "\n" +
        " Message : " + message
      );

      const url = "https://wa.me/+919788537772?text=" + formattedMessage;
      window.open(url, '_blank');
    }
  };

  return (
    <div>
      <Helmet>
        <title>UPVC Modular Kitchen Design Services in Hosur
        </title>
        <meta name="description" content="Elevate your kitchen experience with Monisha Interior's UPVC Modular Kitchen Design services in Hosur. 
"
        />

        {/* Other meta tags, link tags, etc. */}
      </Helmet>
      {/* service banner start.... */}
      <section className="about-banner">
        <div className="about-banner-images">
          <img src="/images/monisha/4.jpeg" />

          <div className="about-content">
            <h1>Moduler Kitchen</h1>
          </div>
          <div className="about-banner-nav">
            <div className="aboutNav">
            <CiHome className='some' /> <Link to="/" ><span>Home</span> </Link> / Moduler
            </div>

          </div>
        </div>
      </section>

      {/* .....................banner end.......... */}

      {/* service left page start.. */}
      <div className="more-servicepage-section">
        <div className="more-servicepage-container container">
          <div className="more-servicepage-boxes">


            <div className="more-servicepage-details">
              <h1>More Services</h1>

              <hr className="more-service-solid" />
              <div className="more-service-hover">
              </div>
               <div className="moduler-list-part bg">
                <span><p>Upvc Moduler Kitchen Designs In Hosur</p></span>
              </div>

              <Link to="/Wardobes"> <div className="moduler-list-part">
                <span><p>Upvc Wardobes Interior Designs In Hosur </p></span>
              </div></Link>

              <Link to="/poojaunit"><div className="moduler-list-part">
                <span><p>Upvc Poojaa Unit Interior Designs In Hosur</p></span>
              </div></Link>

              <Link to="/Tvunit"><div className="moduler-list-part">
                <span><p>Upvc Tv Unit Interior Designs In Hosur</p></span>
              </div></Link>

              <Link to="/Dressing"><div className="moduler-list-part">
                <span><p>Upvc Dressing Table Interior Designs In Hosur</p></span>
              </div></Link>

              <Link to="/Bathroom"><div className="moduler-list-part">
                <span><p>Upvc Bathroom Doors Interior Designs In Hosur</p></span>
              </div></Link>
              <Link to="/False"> <div className="moduler-list-part">
                <span><p>Upvc False Ceiling Interior Designs In Hosur</p></span>
              </div></Link>

            </div>
            {/* more service contact section */}

            <div className="more-service-contact" >

              <div className="more-service-contactinfo">
                <img src="/images/monisha/contact/mob.png" />
                <h3>Phone Number</h3>
                <p>+097885 37772</p>

                <p>+094433 48032</p>
              </div>

              <div className="more-service-contactinfo">
                <img src="/images/monisha/contact/email-to.png" />
                <h3>Email Address</h3>


                <p>  madaiyanpvcinteriorhsr@gmail.com</p>
              </div>

              <div className="more-service-contactinfo">
                <img src="/images/monisha/contact/end-to-end.png" />
                <h3>Location</h3>


                <p>  No.3 Akila Nillayam,<br />Trend City
                  Oppsite,<br />Chithanapalli Village,<br />
                  Nallur Post Hosur,<br />
                  Tamilnadu 635109,India</p>
              </div>

            </div>
            {/* ..................gallery section start........... */}
            <div className="more-service-gallery">

              <h4>Gallery</h4>
              <hr className="more-service-solid" />
              <div className="more-gallery-images">

                <img src="/images/monisha/service/moduler/1.jpg" />
                <img src="/images/monisha/service/moduler/2.jpg" />
                <img src="/images/monisha/service/moduler/3.jpg" />
                <img src="/images/monisha/service/moduler/4.webp" />
                <img src="/images/monisha/service/moduler/5.webp" />
                <img src="/images/monisha/service/moduler/6.jpg" />
              </div>

            </div>

            {/* ......contact form for service page ...... */}

            <div className="contact-form-servicevalid">
              <h4 className="contact-form-head">Contact Now</h4>
              <form onSubmit={handleFormSubmit}>
                <div className="consultant-boxs">

                  <div className="consultant-mini">

                    <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                    <label htmlFor="name">Your Name</label>
                  </div>
                  <div className="consultant-mini">
                    <input type="number" id="number" value={number} onChange={(e) => setNumber(e.target.value)} required />
                    <label htmlFor="number">Your Number</label>
                  </div>
                </div>
                <div className="consultant-min">
                  <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  <label htmlFor="email">Your Email</label>
                </div>
                <div className="consultant-min">
                  <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} cols="30" rows="5" required />
                  <label htmlFor="message">Message</label>
                </div>
                <button type="submit" className="btn">

                  <span className="btn-2">Send Message</span>

                </button>
              </form>
            </div>

            {/* ..... service page right startTransition......... */}





          </div>


          <div className="moduler-interior-theme">
            <img src="/images/monisha/service/moduler/bg1.jpg" />
            <h4>Modular Kitchen Interior Design in Hosur</h4>
            <ul>
              <li><p>Welcome to Monisha Interior, your premier destination for cutting-edge UPVC modular kitchen designs in Hosur. We understand that the kitchen is the heart of every home, and our expert team is dedicated to crafting functional and aesthetically pleasing spaces tailored to your unique needs.
              </p></li>
              <li><p>Our team of designers and craftsmen brings years of experience to the table, ensuring a seamless and professional design process.We use only the finest UPVC materials, known for their durability, resistance to moisture, and low maintenance, ensuring your kitchen stands the test of time.Your preferences and vision are at the forefront of our design process. We work closely with you to create a kitchen that suits your lifestyle.Based in Hosur, we understand the local design preferences and incorporate them into our UPVC modular kitchen designs.
              </p>
              </li>  </ul>


            <h4>Kitchen Interior Designs</h4>
            <h5> Premium UPVC Cabinetry:
            </h5>
            <p>Elevate your kitchen aesthetics with our premium UPVC cabinetry. Durable, easy to maintain, and available in a range of styles, our cabinets are crafted to enhance both form and function.
</p>


            <h5> Innovative Storage Solutions: </h5>
            <p>From clever pull-out drawers to intelligent pantry designs, we ensure every inch of your kitchen is utilized effectively.
.</p>

            <h5>Functional Kitchen Islands: </h5>
            <p>Enhance the functionality of your kitchen with a thoughtfully designed island. Whether for extra workspace or casual dining, our kitchen islands are crafted to meet your specific requirements.
</p>


            <h5>Appliance Integration:
 </h5>
            <p>Seamlessly integrate modern kitchen appliances into your design. We ensure your kitchen not only looks stunning but also functions efficiently with the latest technology.
</p>



            {/* ......quality & why we best page create....... */}
            <div className="quality-service-section">

              <div className="quality-section-boxs">
                <div className="quality-info">
                  <h5>Quality</h5>

                  <p>Experience the luxury of high-quality UPVC countertops that combine durability with elegance. Our selection of finishes and textures allows you to create a kitchen that suits your taste.Tailor your kitchen to perfection with our customized layouts. We design spaces that optimize efficiency, enhance workflow, and reflect your personal style.
</p>
                  <Link to="/"> <p> <span className="readmore">Read More</span></p></Link>
                </div>

                <div className="quality-info">
                  <h5>Why We Best</h5>

                  <p> Take a glimpse into our portfolio to witness the magic of UPVC Modular Kitchen Designs crafted by Monisha Interior. Each project tells a unique story of sophistication, functionality, and timeless beauty.
</p>

                  <Link to="/"> <p> <span className="readmore">Read More</span></p></Link>
                </div>

              </div>
            </div>

            {/* .....lightbox effect.... */}
            <div className="servicelight-card-area">
              <div className="servicelight-wrapper">
                <h4>Our Latest Project</h4>
                <div className="servicelight-box-area">

                  <div className="box">
                    <img src="/images/monisha/service/moduler/1.jpg" />
                    <div className="overlay">
                      <h3>Moduler Kitchen</h3>
                      {/* <p>kitchen Interior</p> */}

                    </div>
                  </div>

                  <div className="box">
                    <img src="/images/monisha/service/moduler/2.jpg" />
                    <div className="overlay">
                      <h3>Moduler Kitchen</h3>
                      {/* <p>kitchen Interior</p> */}

                    </div>
                  </div>

                  <div className="box">
                    <img src="/images/monisha/service/moduler/4.webp" />
                    <div className="overlay">
                      <h3>Moduler Kitchen</h3>
                      {/* <p>kitchen Interior</p> */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ......faq  for service page.... */}



          </div>
        </div>
      </div>

      <Footer />
    </div>


  )
}
export default Service;