import React from "react";
import './Aboutservices.css';
function Aboutservices() {
  return (
    <div>



      {/* ..................home page /service page start.............. */}
      <div className="how-wedoit-section">
        <div className="how-wedoit-container ">
          <div className="how-wedoit-boxs">

            {/* <div className="home-service-image">
              <img src="images/monisha/home/howwedoit.webp" width="600px" height="782px" />
            </div> */}

            <div className="how-wedoit-para">
              <div className="how-wedoitpara-container container">
              

              <h1 className="how-wedoitpara-head">Best Interior Designer In Hosur</h1>
              <h2 className="how-wedoitpara-subhead"> <div class="divider">HERE IS HOW WE DO IT</div></h2>
              <h3>Steps for the interior in Hosur.</h3>

              <div className="how-wedoit-dediv">
                <div className="how-wedoit-details">
                  <img src="images/monisha/home/planning.png" alt="Your Image" class="rotate-y" />
                  <h1>Strategic Planning</h1>
                  <p>Our strategic approach ensures that every step contributes to the overall success of your project.</p>
                </div>


                <div className="how-wedoit-details">
                  <img src="images/monisha/home/concept.png" />
                  <h1>Developing Concepts</h1>
                  <p>Creativity takes centre stage as we develop concepts that breathe life into your space.</p>
                </div>

                <div className="how-wedoit-details">
                  <img src="images/monisha/home/designdevelopment.png" />
                  <h1>Design Development</h1>
                  <p>Precision and attention to detail define our design development phase.</p>
                </div>

                <div className="how-wedoit-details">
                  <img src="images/monisha/home/construction.png" />
                  <h1>Construction Work</h1>

                  <p>Turning dreams into reality requires expertise in construction and our skilled craftsmen.</p>

                    </div>
                </div>
              </div>
            </div>
            <div className="home-service-image">
              <img src="images/monisha/home/howwedoit.webp" width="600px" height="600px" />
            </div>
          </div>
        </div>
      </div>

      {/* .................home service page end........... */}
    </div>
  )
}
export default Aboutservices;