import React, { useState } from "react";
import './Poojaunit.css';
import { Link } from 'react-router-dom';
import { CiHome } from "react-icons/ci";
import Footer from "../../Footer/Footer";
import { Helmet } from 'react-helmet';

// import Cardhover from "../Cardhover";
//import Aboutfaq2 from "../Aboutfaq2";

const Poojaunit = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleFormSubmit = (event) => {
        event.preventDefault();

        if (name === '' || email === '' || number === '' || message === '') {
            alert("Please fill in all fields");
        } else {
            const formattedMessage = encodeURIComponent(
                "Contact Page info" + "\n" +
                " Name : " + name + "\n" +
                " Email : " + email + "\n" +
                " Mobile Number : " + "+" + number + "\n" +
                " Message : " + message
            );

            const url = "https://wa.me/+919788537772?text=" + formattedMessage;
            window.open(url, '_blank');
        }
    };

    return (
        <div>
            <Helmet>
                <title>uPVC Pooja Unit Interior Design Services in Hosur
                </title>
                <meta name="description" content="Elevate your sacred space with Monisha Interior's uPVC Pooja Unit Interior Design services in Hosur. Discover innovative designs, premium uPVC materials, and personalized solutions for a divine ambiance. 
"

                />

                {/* Other meta tags, link tags, etc. */}
            </Helmet>
            {/* service banner start.... */}
            <section className="about-banner">
                <div className="about-banner-images">
                    <img src="/images/monisha/4.jpeg" />

                    <div className="about-content">
                        <h1>Poojaunit Interior</h1>
                    </div>
                    <div className="about-banner-nav">
                        <div className="aboutNav">
                        <CiHome className='some' /> <Link to="/" ><span>Home</span> </Link> / Poojaunit
                        </div>

                    </div>
                </div>
            </section>

            {/* .....................banner end.......... */}

            {/* service left page start.. */}
            <div className="more-servicepage-section">
                <div className="more-servicepage-container container">
                    <div className="more-servicepage-boxes">


                        <div className="more-servicepage-details">
                            <h1>More Services</h1>

                            <hr className="more-service-solid" />
                            <div className="more-service-hover">
                            </div>

                            <Link to="/Moduler"><div className="moduler-list-part">
                                <span><p>Upvc Moduler Kitchen Designs In Hosur</p></span>
                            </div></Link>

                            <Link to="/Wardobes"><div className="moduler-list-part">
                                <span><p>Upvc Wardobes Interior Designs In Hosur </p></span>
                            </div></Link>

                            <Link to="/poojaunit"><div className="moduler-list-part bg">
                                <span><p>Upvc Pooja Unit Interior Designs In Hosur</p></span>
                            </div></Link>

                            <Link to="/Tvunit"><div className="moduler-list-part">
                                <span><p>Upvc Tv Unit Interior Designs In Hosur</p></span>
                            </div></Link>

                            <Link to="/Dressing"><div className="moduler-list-part">
                                <span><p>Upvc Dressing Table Interior Designs In Hosur</p></span>
                            </div></Link>

                            <Link to="/Bathroom"><div className="moduler-list-part">
                                <span><p>Upvc Bathroom Doors Interior Designs In Hosur</p></span>
                            </div></Link>


                            <Link to="/False" ><div className="moduler-list-part">
                                <span><p>Upvc False Ceiling Interior Designs In Hosur</p></span>
                            </div></Link>

                        </div>
                        {/* more service contact section */}

                        <div className="more-service-contact" >

                            <div className="more-service-contactinfo">
                                <img src="/images/monisha/contact/mob.png" />
                                <h3>Phone Number</h3>
                                <p>+097885 37772</p>

                                <p>+094433 48032</p>
                            </div>

                            <div className="more-service-contactinfo">
                                <img src="/images/monisha/contact/email-to.png" />
                                <h3>Email Address</h3>


                                <p>  madaiyanpvcinteriorhsr@gmail.com</p>
                            </div>

                            <div className="more-service-contactinfo">
                                <img src="/images/monisha/contact/end-to-end.png" />
                                <h3>Location</h3>


                                <p>  No.3 Akila Nillayam,<br />Trend City
                                    Oppsite,<br />Chithanapalli Village,<br />
                                    Nallur Post Hosur,<br />
                                    Tamilnadu 635109,India</p>
                            </div>

                        </div>
                        {/* ..................gallery section start........... */}
                        <div className="more-service-gallery">

                            <h4>Gallery</h4>
                            <hr className="more-service-solid" />
                            <div className="more-gallery-images">

                                <img src="/images/monisha/service/poojaunit/2.jpg" />
                                <img src="/images/monisha/service/poojaunit/3.jpg" />
                                <img src="/images/monisha/service/poojaunit/5.jpg" />
                                <img src="/images/monisha/service/poojaunit/7.jpg" />
                                <img src="/images/monisha/service/poojaunit/8.jpg" />
                                <img src="/images/monisha/service/poojaunit/11.jpg" />
                            </div>

                        </div>

                        {/* ......contact form for service page ...... */}

                        <div className="contact-form-servicevalid">
                            <h4 className="contact-form-head">Contact Now</h4>
                            <form onSubmit={handleFormSubmit}>
                                <div className="consultant-boxs">

                                    <div className="consultant-mini">

                                        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                                        <label htmlFor="name">Your Name</label>
                                    </div>
                                    <div className="consultant-mini">
                                        <input type="number" id="number" value={number} onChange={(e) => setNumber(e.target.value)} required />
                                        <label htmlFor="number">Your Number</label>
                                    </div>
                                </div>
                                <div className="consultant-min">
                                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                    <label htmlFor="email">Your Email</label>
                                </div>
                                <div className="consultant-min">
                                    <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} cols="30" rows="5" required />
                                    <label htmlFor="message">Message</label>
                                </div>
                                <button type="submit" className="btn">

                                    <span className="btn-2">Send Message</span>

                                </button>
                            </form>
                        </div>

                        {/* ..... service page right startTransition......... */}





                    </div>


                    <div className="moduler-interior-theme">
                        <img src="/images/monisha/service/poojaunit/1.jpg" />
                        <h4> uPVC  Poojaunit Interior Design in Hosur</h4>
                        <ul>
                            <li><p>Transform your sacred space into a haven of divine tranquility with Monisha Interior's exceptional uPVC Pooja Unit Interior Design services in Hosur. Our team is dedicated to creating spiritual sanctuaries that reflect your beliefs and elevate your home's ambiance.
                            </p></li>
                            <li><p>Our designs reflect a deep understanding of cultural and spiritual practices, ensuring that your uPVC Pooja Unit is a symbol of reverence and respect.With a team of skilled craftsmen, we ensure precision and attention to detail in every element of your Pooja Unit, creating a space that exudes spiritual serenity.Your spiritual journey is unique, and so is our approach. We collaborate closely with you to understand your preferences and create a Pooja Unit that aligns with your spiritual vision.
                            </p>
                            </li>  </ul>


                        <h4>uPVC Poojaunit Interior Designs</h4>
                        <h5>Customized Pooja Unit Designs</h5>
                        <p>Immerse yourself in personalized spiritual experiences with our customized uPVC Pooja Unit designs. We craft sacred spaces that resonate with your beliefs and seamlessly integrate with your home's interior.
                        </p>


                        <h5> Premium uPVC Materials:
                        </h5>
                        <p>Embrace the beauty and durability of uPVC materials in your Pooja Unit. Our commitment to quality ensures that your sacred space is adorned with premium materials that stand the test of time.
                        </p>

                        <h5> Innovative Lighting Solutions:
                        </h5>
                        <p>Illuminate your prayers with our innovative lighting solutions. From ambient lighting to highlight intricate details, we create a serene atmosphere that enhances your spiritual experience.
                        </p>


                        <h5>Cultural Sensitivity:
                        </h5>
                        <p>Understanding the importance of cultural nuances, we design Pooja Units that respect and celebrate your traditions. Our designs incorporate elements that resonate with the spiritual practices you hold dear.
                            .</p>



                        <h5>Space Optimization:</h5>
                        <p>Even in compact spaces, we optimize the Pooja Unit layout for functionality and grace. Our designs ensure that every inch of the sacred space is utilized effectively, allowing for seamless prayer rituals.
                        </p>



                        {/* ......quality & why we best page create....... */}
                        <div className="quality-service-section">

                            <div className="quality-section-boxs">
                                <div className="quality-info">
                                    <h5>Quality</h5>

                                    <p>At Monisha Interior, we believe in setting the
                                        standard for quality in uPVC Pooja Unit Interior Design. Our commitment to excellence is evident in every detail of our services.We source and use only the finest uPVC materials, known for their durability, resistance to environmental factors, and low maintenance.
                                    </p>
                                    <Link to="/"> <p> <span className="readmore">Read More</span></p></Link>
                                </div>

                                <div className="quality-info">
                                    <h5>Why We Best</h5>

                                    <p>We understand the significance of cultural nuances in Pooja Unit design. Our team incorporates elements that respect and celebrate your traditions, ensuring your sacred space is in harmony with your beliefs.Your satisfaction is at the core of our philosophy. We prioritize open communication, actively listen to your preferences, and work collaboratively to bring your vision of a perfect uPVC Pooja Unit to life.Being based in Hosur, we have a deep understanding of the local lifestyle and design preferences.
                                    </p>

                                    <Link to="/"> <p> <span className="readmore">Read More</span></p></Link>
                                </div>

                            </div>
                        </div>

                        {/* .....lightbox effect.... */}
                        <div className="servicelight-card-area">
                            <div className="servicelight-wrapper">
                                <h4>Our Latest Project</h4>
                                <div className="servicelight-box-area">

                                    <div className="box">
                                        <img src="/images/monisha/service/poojaunit/2.jpg" />
                                        <div className="overlay">
                                            <h3>Poojaunit Interior</h3>
                                            {/* <p>kitchen Interior</p> */}

                                        </div>
                                    </div>

                                    <div className="box">
                                        <img src="/images/monisha/service/poojaunit/8.jpg" />
                                        <div className="overlay">
                                            <h3>Poojaunit Interior</h3>
                                            {/* <p>kitchen Interior</p> */}

                                        </div>
                                    </div>

                                    <div className="box">
                                        <img src="/images/monisha/service/poojaunit/5.jpg" />
                                        <div className="overlay">
                                            <h3>Poojaunit Interior</h3>
                                            {/* <p>kitchen Interior</p> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ......faq  for service page.... */}



                    </div>
                </div>
            </div>
            <Footer />

        </div>


    )
}
export default Poojaunit;
